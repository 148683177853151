import * as React from 'react'
import Layout from '../../components/layout'
import * as styles from './index.module.scss'

const PrivacyPage = () => {
  return (
    <Layout
      pageTitle="Политика приватности"
      className={styles.content}
    >
      <h2>Политика приватности</h2>

      <h3>1. Общие положения</h3>

      <p>
        1.1. Настоящая политика обработки персональных данных (далее – Политика) составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» (далее - Закон о персональных данных) и определяет порядок обработки персональных данных интернет-пользователей (далее – Пользователь) и меры по обеспечению безопасности персональных данных, предпринимаемые ООО «Люкстекс» (далее – Оператор).
      </p>
      <p>
        1.2. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
      </p>
      <p>
        1.3. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href="https://sjersy.ru">https://sjersy.ru</a> (далее – Сайт).
      </p>

      <h3>2. Обрабатываемые данные</h3>

      <p>
        2.1. Оператор не осуществляет сбор персональных данных Пользователя с использованием Сайта.
      </p>
      <p>
        2.2. Все данные, собираемые на Сайте, предоставляются и принимаются в обезличенной форме (далее – Обезличенные данные).
      </p>
      <p>
        2.3. Оператор вправе устанавливать требования к составу Обезличенных данных Пользователя, которые собираются использованием Сайта.
      </p>
      <p>
        2.4. Оператор не осуществляет проверку достоверности предоставляемых данных и наличия у Пользователя необходимого согласия на их обработку в соответствии с настоящей Политикой, полагая, что Пользователь действует добросовестно, осмотрительно и прилагает все необходимые усилия к поддержанию такой информации в актуальном состоянии и получению всех необходимых согласий на ее использование.
      </p>
      <p>
        2.5. Пользователь осознает и принимает возможность использования на Сайте программного обеспечения третьих лиц, в том числе Яндекс Метрика и Пиксель ВКонтакте, в результате чего такие лица могут получать и передавать Обезличенные данные.
      </p>
      <p>
        2.6. Состав и условия сбора обезличенных данных с использованием программного обеспечения третьих лиц определяются непосредственно их правообладателями и могут включать:
        <ul>
          <li>данные браузера (тип, версия, cookie);</li>
          <li>данные устройства и место его положения;</li>
          <li>данные операционной системы (тип, версия, разрешение экрана);</li>
          <li>данные запроса (время, источник перехода, IP-адрес).</li>
        </ul>
      </p>
      <p>
        2.7. Оператор не несет ответственность за порядок использования Обезличенных данных Пользователя третьими лицами.
      </p>

      <h3>3. Цели обработки персональных данных</h3>

      <p>
        3.1. Оператор обрабатывает персональные данные Пользователя для его доступа к сервисам, информации и/или материалам, содержащимся на Сайте.
      </p>
      <p>
        3.2. Оператор использует данные для проведения маркетинговых, статистических и иных исследований.
      </p>

      <h3>4. Передача данных</h3>

      <p>
        4.1. Оператор вправе передать данные третьим лицам в следующих случаях:
      </p>
      <p>
        4.1.1. Пользователь выразил свое согласие на такие действия, включая случаи применения Пользователем настроек используемого программного обеспечения, не ограничивающих предоставление определенной информации.
      </p>
      <p>
        4.1.2. По запросу суда или иного уполномоченного государственного органа в рамках установленной законодательством процедуры.
      </p>
      <p>
        4.1.3. Передача данных требуется в соответствии с целями обработки данных.
      </p>
      <p>
        4.1.4. Передача необходима в рамках использования Пользователем функциональных возможностей Сайта.
      </p>

      <h3>5. Заключительные положения</h3>

      <p>
        5.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <a href="mailto:info@sjersy.ru">info@sjersy.ru</a>.
      </p>
      <p>
        5.2. Настоящая Политика может быть изменена или прекращена Оператором в одностороннем порядке без предварительного уведомления Пользователя. Новая редакция Политики вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики.
      </p>
      <p>
        5.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://sjersy.ru/privacy">https://sjersy.ru/privacy</a>.
      </p>

      <blockquote>Действующая редакция Политики приватности от 5 августа 2022 г.</blockquote>

    </Layout>
  )
}

export default PrivacyPage